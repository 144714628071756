import React from "react";
import { PageLayout } from "../components/Layout";

const NotFoundPage = () => (
  <PageLayout>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </PageLayout>
);

export default NotFoundPage;
